<template>
  <div
    class="card mb-4"
    v-if="
      !movie.adult &&
      this.$store.state.getMovie.status.trailersMovieLoading &&
      trailersMovie.length
    "
  >
    <div class="card-header bg-success">
      <h4 class="text-center text-white my-2">تریلر {{ movie.title }}</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <template v-for="trailer in trailersMovie" :key="trailer.id">
          <div class="col-md-6" v-if="trailer.site === 'YouTube'">
            <iframe
              width="100%"
              height="450"
              :src="'https://www.youtube.com/embed/' + trailer.key"
            ></iframe>
          </div>
          <div class="col-md-6 my-2" v-if="trailer.site === 'Vimeo'">
            <iframe
              width="100%"
              height="450"
              :src="'https://player.vimeo.com/video/' + trailer.key"
            ></iframe>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrailersMovie",
  props: {
    movie: {
      type: Object,
      required: true,
    },
    trailersMovie: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
